import styles from './Navbar.module.scss'
import React from 'react'
import cx from 'classnames'

import { Github, Twitter } from './../SocialIcons/SocialIcons'

import Container from '../../layouts/Container/Container'
import Link from './../Link/Link'
import { BrandNew as BrandLogo } from '../Brand/Brand'
import NavbarSecondary from '../NavbarSecondary/NavbarSecondary'
import NavbarPrimary from '../NavbarPrimary/NavbarPrimary'
import NavbarMenu from '../NavbarMenu/NavbarMenu'

const Navbar = ({ bleed, light, children, className, ...restProps }) => {
  return (
    <Container
      className={cx({ [styles.wrapper]: !light, [styles.light]: light })}
      bleed
    >
      <Container
        narrow
        component="nav"
        className={cx({
          [styles.root]: true,
          [className]: className,
        })}
        {...restProps}
      >
        <NavbarPrimary>
          <Link to="/" style={{ color: 'white' }}>
            <BrandLogo />
          </Link>
        </NavbarPrimary>
        <NavbarSecondary>
          {/* <NavbarMenu to="/" style={{ marginRight: 24 }}>
            Home
          </NavbarMenu> */}
          <NavbarMenu to="/work" style={{ marginRight: 24 }}>
            Work
          </NavbarMenu>
          <NavbarMenu to="/about" style={{ marginRight: 24 }}>
            About
          </NavbarMenu>
          <NavbarMenu to="/notes" style={{ marginRight: 24 }}>
            Notes
          </NavbarMenu>
          <a
            href="https://github.com/vasilenka"
            target="_blank"
            rel="noreferrer noopener"
            style={{ marginRight: 32 }}
          >
            <Github className={styles.icon} />
          </a>
          <a
            href="https://twitter.com/vasilenka_"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Twitter className={styles.icon} />
          </a>
        </NavbarSecondary>
      </Container>
    </Container>
  )
}

export default Navbar
