import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ children, external, to, ...restPprops }) =>
  external ? (
    <a href={to} {...restPprops} rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <GatsbyLink to={to} {...restPprops}>
      {children}
    </GatsbyLink>
  )

export default Link
