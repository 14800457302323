import React from 'react'

import BasecampIcon from './icons/basecamp.inline.svg'
import BehanceIcon from './icons/behance.inline.svg'
import DiscordIcon from './icons/discord.inline.svg'
import DribbbleIcon from './icons/dribbble.inline.svg'
import DropboxIcon from './icons/dropbox.inline.svg'
import FacebookIcon from './icons/facebook.inline.svg'
import GithubIcon from './icons/github.inline.svg'
import GmailIcon from './icons/gmail.inline.svg'
import GoogleIcon from './icons/google.inline.svg'
import InstagramIcon from './icons/instagram.inline.svg'
import LinkedinIcon from './icons/linkedin.inline.svg'
import MediumIcon from './icons/medium.inline.svg'
import MessengerIcon from './icons/messenger.inline.svg'
import PatreonIcon from './icons/patreon.inline.svg'
import PaypalIcon from './icons/paypal.inline.svg'
import RssIcon from './icons/rss.inline.svg'
import ShopifyIcon from './icons/shopify.inline.svg'
import SkypeIcon from './icons/skype.inline.svg'
import SnapchatIcon from './icons/snapchat.inline.svg'
import TelegramIcon from './icons/telegram.inline.svg'
import TwitchIcon from './icons/twitch.inline.svg'
import TwitterIcon from './icons/twitter.inline.svg'
import VimeoIcon from './icons/vimeo.inline.svg'
import WhatsappIcon from './icons/whatsapp.inline.svg'
import YoutubeIcon from './icons/youtube.inline.svg'

export const Basecamp = ({ className, ...restProps }) => {
  return <BasecampIcon className={className} {...restProps} />
}
export const Behance = ({ className, ...restProps }) => {
  return <BehanceIcon className={className} {...restProps} />
}
export const Discord = ({ className, ...restProps }) => {
  return <DiscordIcon className={className} {...restProps} />
}
export const Dribbble = ({ className, ...restProps }) => {
  return <DribbbleIcon className={className} {...restProps} />
}
export const Dropbox = ({ className, ...restProps }) => {
  return <DropboxIcon className={className} {...restProps} />
}
export const Facebook = ({ className, ...restProps }) => {
  return <FacebookIcon className={className} {...restProps} />
}
export const Github = ({ className, ...restProps }) => {
  return <GithubIcon className={className} {...restProps} />
}
export const Gmail = ({ className, ...restProps }) => {
  return <GmailIcon className={className} {...restProps} />
}
export const Google = ({ className, ...restProps }) => {
  return <GoogleIcon className={className} {...restProps} />
}
export const Instagram = ({ className, ...restProps }) => {
  return <InstagramIcon className={className} {...restProps} />
}
export const Linkedin = ({ className, ...restProps }) => {
  return <LinkedinIcon className={className} {...restProps} />
}
export const Medium = ({ className, ...restProps }) => {
  return <MediumIcon className={className} {...restProps} />
}
export const Messenger = ({ className, ...restProps }) => {
  return <MessengerIcon className={className} {...restProps} />
}
export const Patreon = ({ className, ...restProps }) => {
  return <PatreonIcon className={className} {...restProps} />
}
export const Paypal = ({ className, ...restProps }) => {
  return <PaypalIcon className={className} {...restProps} />
}
export const Rss = ({ className, ...restProps }) => {
  return <RssIcon className={className} {...restProps} />
}
export const Shopify = ({ className, ...restProps }) => {
  return <ShopifyIcon className={className} {...restProps} />
}
export const Skype = ({ className, ...restProps }) => {
  return <SkypeIcon className={className} {...restProps} />
}
export const Snapchat = ({ className, ...restProps }) => {
  return <SnapchatIcon className={className} {...restProps} />
}
export const Telegram = ({ className, ...restProps }) => {
  return <TelegramIcon className={className} {...restProps} />
}
export const Twitch = ({ className, ...restProps }) => {
  return <TwitchIcon className={className} {...restProps} />
}
export const Twitter = ({ className, ...restProps }) => {
  return <TwitterIcon className={className} {...restProps} />
}
export const Vimeo = ({ className, ...restProps }) => {
  return <VimeoIcon className={className} {...restProps} />
}
export const Whatsapp = ({ className, ...restProps }) => {
  return <WhatsappIcon className={className} {...restProps} />
}
export const Youtube = ({ className, ...restProps }) => {
  return <YoutubeIcon className={className} {...restProps} />
}
