import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetaData = () => {
  const { site } = useStaticQuery(graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `)

  let siteMetadata = site.siteMetadata
  return siteMetadata
}

export default useSiteMetaData
