import styles from './Default.module.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import useSiteMetadata from './../../hooks/query/useSiteMetadata'

import './Default.module.scss'
import '../../assets/fonts/fonts'

import Navbar from '../../components/Navbar/Navbar'
import MobileNav from '../../components/MobileNav/MobileNav'

const Layout = ({ children, light }) => {
  const siteMetadata = useSiteMetadata()
  return (
    <main className={styles.root}>
      <Navbar bleed light={light} />
      {children}
      {/* <MobileNav /> */}
    </main>
  )
}
// <StaticQuery
//   query={query}
//   render={({
//     site: {
//       siteMetadata: { title, description, author, url },
//     },
//     mdx: {
//       frontmatter: {
//         mdxDescription,
//         mdxPath,
//         mdxKeywords,
//         mdxDate,
//         mdxTitle,
//       },
//     },
//   }) => {
//     return (
//       <main className={styles.root}>
//         <Navbar bleed />
//         {children}
//       </main>
//     )
//   }}
// />

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

// const query = graphql`
//   query defaultQuery {
//     site {
//       siteMetadata {
//         title
//         description
//         author
//         url: siteUrl
//       }
//     }
//     mdx {
//       frontmatter {
//         mdxDescription: description
//         mdxPath: path
//         mdxKeywords: keywords
//         mdxDate: date(formatString: "MMMM DD, YYYY")
//         mdxTitle: title
//       }
//     }
//   }
// `
